(function( factory ) {
	if ( typeof define === "function" && define.amd ) {
		define( ["jquery", "../jquery.validate"], factory );
	} else if (typeof module === "object" && module.exports) {
		module.exports = factory( require( "jquery" ) );
	} else {
		factory( jQuery );
	}
}(function( $ ) {

/*
 * Translated default messages for the jQuery validation plugin.
 * Locale: DE (German, Deutsch)
 */
$.extend( $.validator.messages, {
	required: "Questo campo è obbligatorio.",
	maxlength: $.validator.format('Inserisci un massimo di {0} caratteri.'),
	minlength: $.validator.format('Inserisci un minimo di {0} caratteri.'),
	rangelength: $.validator.format('Inserisci un minimo di {0} e un massimo di {1} caratteri.'),
	email: 'Inserire un indirizzo e-mail valido',
	url: 'Inserire un URL valido.',
	date: 'Inserire una data valida.',
	number: 'Inserire un numero.',
	digits: 'Inserisci solo cifre.',
	equalTo: 'Ripeti l’inserimento del valore',
	range: $.validator.format('Inserisci un valore tra {0} e {1}.'),
	max: $.validator.format('Inserisci un valore inferiore o uguale a {0}'),
	min: $.validator.format('Inserisci un valore superiore o uguale a {0}'),
	creditcard: 'Inserisci un numero di carta di credito valido'
} );
return $;
}));
