'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        'invalid': 'Inserisci dati validi',
        'legalDate': 'Per ordinare nello Shop Jack Wolfskin devi avere almeno 18 anni.',
        'maxlength': $.validator.format('Inserisci un massimo di {0} caratteri.'),
        'minlength': $.validator.format('Inserisci un minimo di {0} caratteri.'),
        'rangelength': $.validator.format('Inserisci un minimo di {0} e un massimo di {1} caratteri.'),
        'email': 'Inserire un indirizzo e-mail valido',
        'url': 'Inserire un URL valido.',
        'date': 'Inserire una data valida.',
        'number': 'Inserire un numero.',
        'digits': 'Inserisci solo cifre.',
        'equalTo': 'Ripeti l’inserimento del valore',
        'range': $.validator.format('Inserisci un valore tra {0} e {1}.'),
        'max': $.validator.format('Inserisci un valore inferiore o uguale a {0}'),
        'min': $.validator.format('Inserisci un valore superiore o uguale a {0}'),
        'creditcard': 'Inserisci un numero di carta di credito valido',
        'invalidZip': 'Indica il tuo codice postale valido.',
        'couponCodeMistakenForGiftCard': 'Inserisci un codice coupon o un codice promozionale valido. Se hai una carta regalo, puoi utilizzarla inserendola nel checkout',
        'invalidGiftCard': ' Indica il codice della tua carta regalo. Se hai un codice coupon o un codice promozionale, puoi utilizzarlo inserendolo nel carrello.',
        'streetMissingHouseNumber': 'Non ha indicato il numero civico, è corretto?',
        'invalidPassword': 'La password deve essere lunga almeno 8 caratteri e deve contenere almeno un carattere speciale, una lettera maiuscola e un numero.',
        'passwordsDontMatch': 'le password non coincidono.',
        'selectReturnReason': 'Seleziona il motivo del reso per continuare'
    });
    return $;
}));
